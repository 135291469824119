const userReducer = (state = { agentData: null, agentError: null }, action) => {
	switch (action.type) {
		case "AGENT_DATA_RECEIVED":
			return {
				...state,
				agentData: action.result,
				agentError: null,
			};

		case "USERS_LIST_DATA_RECEIVED":
			return {
				...state,
				userList: action.data,
			};
		case "AGENT_DATA_ERROR":
			return {
				...state,
				agentError: action.err,
			};
		default:
			return state;
	}
};

export default userReducer;
