import React, { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { TimeLineContext } from "../Store/TimeLineContext";
import { fetchAgent } from "../Store/Actions/userActions";

import iconSocials from "../Assets/images/icons/icon-socials.svg";

export default function AgentPage() {
	const { push } = useHistory();
	const dispatch = useDispatch();
	const { agentData, agentError } = useSelector((state) => state.userReducer);

	const { agent } = useContext(TimeLineContext);

	useEffect(() => {
		if (agent) dispatch(fetchAgent(agent.aSerial));
		// eslint-disable-next-line
	}, [agent]);

	return (
		<div className="App-body">
			{agentData && (
				<div className="content">
					<div className="productData">
						<div className="back-link" onClick={() => push("/trace")}>
							<span className="material-icons">fast_rewind</span>
						</div>

						<p className="productDataTitle">{agentData.username}</p>
						<p className="data-small">
							{agent.locationData.bizLocationCity}, {agent.locationData.bizLocationCountry}
						</p>

						<div
							className="productPhoto"
							style={{ backgroundImage: `url(${"https://cdn.shopify.com/s/files/1/2399/1771/files/Picture4_4f9626fe-24dd-425c-8584-adc2f6084f5d_700x.jpg"})` }} // <-------------------- Data to come from Shopify API
						></div>

						<div className="dataSegment">
							ASOPEP was born as a response to the felt need to organize a way of relating among the 167 members of an association with several objectives: to
							market their own coffee production, form their own form of government and design their own farm conservation model to guarantee resources to
							future generations.
						</div>

						<div className="pageSegment">
							<div>
								<img src={iconSocials} alt="" className="socials" />
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
