import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";

import "./mapbox-gl.css";

export default function Map({ mapData, Transportation = null, clicked, setClickedStep }) {
	const mapContainer = useRef(null);
	const [mapError, setMapError] = useState(null);
	const map = useRef(null);

	const [markers, setNewMarkers] = useState([]);

	function create_marker(color, scale, marker, clicked, list, text = null) {
		marker.marker.remove();
		const index = list.indexOf(marker);
		list.splice(index, 1);
		//**Create cliked marker
		const popup = new mapboxgl.Popup({ offset: 25 }).setText(text);
		let new_marker;
		if (clicked) {
			new_marker = new mapboxgl.Marker({ color: color, scale: scale })
				.setLngLat([marker.marker._lngLat.lng, marker.marker._lngLat.lat])
				.setPopup(popup)
				.addTo(map.current);
		} else {
			new_marker = new mapboxgl.Marker({ color: color, scale: scale }).setLngLat([marker.marker._lngLat.lng, marker.marker._lngLat.lat]).addTo(map.current);
		}
		let m = { id: marker.id, marker: new_marker, clicked: clicked };
		list.push(m);

		return list;
	}
	function setMarkers(clickedMarker = null) {
		//set the tolltip for the markers

		let tooltipText;

		if (clickedMarker.bizActivity === "Exchanged")
			tooltipText = `Goods have been ${clickedMarker.bizActivity} between ${clickedMarker.bizAgent} and ${clickedMarker.bizAgent2}`;
		if (clickedMarker.bizActivity === "Commissioned") tooltipText = `Goods have been ${clickedMarker.bizActivity} by ${clickedMarker.bizAgent}`;

		let markersList = markers;
		// find a clicked marker
		let previously_selected_marker = markersList.find((item) => item.clicked === true);

		//Re-create the marker
		if (previously_selected_marker) {
			create_marker("#0d6d8a", 1, previously_selected_marker, false, markersList);
			//If user clicks on the same marker set marker to default
			if (previously_selected_marker.id === clickedMarker.bizActivityID) {
				setClickedStep();
				return;
			}
		}

		let selected_marker = markersList.find((item) => item.id === clickedMarker.bizActivityID);

		map.current.flyTo({
			center: [selected_marker.marker._lngLat.lng, selected_marker.marker._lngLat.lat],
			zoom: 7,
		});
		create_marker("#1e212e", 1.2, selected_marker, true, markersList, tooltipText);
	}

	// function setLines(clickedLine = null) {
	// 	Transportation.data.features.forEach((el) => {
	// 		if (clicked.uri === el.properties.agent.properties.uri) {
	// 			el.properties.color = "#FF0000";
	// 			if (map.current.getLayer("lines-clicked")) {
	// 				map.current.removeLayer("lines-clicked");
	// 				map.current.removeSource("lines-clicked");
	// 			}
	// 			console.log(el);
	// 			map.current.addSource("lines-clicked", {
	// 				type: "geojson",
	// 				data: { type: "FeatureCollection", features: [el] },
	// 			});

	// 			map.current.addLayer({
	// 				id: "lines-clicked",
	// 				type: "line",
	// 				source: "lines-clicked",
	// 				layout: {
	// 					"line-join": "round",
	// 					"line-cap": "round",
	// 				},
	// 				paint: {
	// 					"line-color": ["get", "color"],
	// 					"line-width": 5,
	// 				},
	// 			});
	// 			console.log(map.current.getLayer("lines-clicked"));
	// 		} else {
	// 			if (map.current.getLayer("lines-clicked")) {
	// 				map.current.removeLayer("lines-clicked");
	// 				map.current.removeSource("lines-clicked");
	// 			}
	// 		}
	// 	});
	// }
	const size = 100;
	const pulsingDot = {
		width: size,
		height: size,
		data: new Uint8Array(size * size * 4),

		// When the layer is added to the map,
		// get the rendering context for the map canvas.
		onAdd: function () {
			const canvas = document.createElement("canvas");
			canvas.width = this.width;
			canvas.height = this.height;
			this.context = canvas.getContext("2d");
		},

		// Call once before every frame where the icon will be used.
		render: function () {
			const duration = 1000;
			const t = (performance.now() % duration) / duration;

			const radius = (size / 2) * 0.3;
			const outerRadius = (size / 2) * 0.7 * t + radius;
			const context = this.context;

			// Draw the outer circle.
			context.clearRect(0, 0, this.width, this.height);
			context.beginPath();
			context.arc(this.width / 2, this.height / 2, outerRadius, 0, Math.PI * 2);
			context.fillStyle = `rgba(255, 200, 200, ${1 - t})`;
			context.fill();

			// Draw the inner circle.
			context.beginPath();
			context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
			context.fillStyle = "rgba(255, 100, 100, 1)";
			context.strokeStyle = "white";
			context.lineWidth = 2 + 4 * (1 - t);
			context.fill();
			context.stroke();

			// Update this image's data with data from the canvas.
			this.data = context.getImageData(0, 0, this.width, this.height).data;

			// Continuously repaint the map, resulting
			// in the smooth animation of the dot.
			map.current.triggerRepaint();

			// Return `true` to let the map know that the image was updated.
			return true;
		},
	};

	useEffect(() => {
		if (map.current) {
			if (clicked) setMarkers(clicked);
			return;
		}

		mapboxgl.accessToken = process.env.REACT_APP_MAP_BOX_KEY;

		map.current = new mapboxgl.Map({
			container: mapContainer.current,
			style: "mapbox://styles/mapbox/light-v10",
			center: [mapData[mapData.length - 1].geometry.coordinates[0], mapData[mapData.length - 1].geometry.coordinates[1]],
			zoom: 7,
		});
		//* *Sort the lines Coordinates to match LngLtd format

		let newLines = [];
		mapData.forEach((el) => {
			if (!el.geometry.coordinates[0]) {
				setMapError("Sorry we are experincing some problems with the map");
				return;
			}
			if (el.properties.bizActivity !== "Commissioned") newLines.push([el.geometry.coordinates[0], el.geometry.coordinates[1]]);
		});

		if (newLines.length > 1) {
			//Post lines to Openroute and get back constructed route

			axios
				.post(
					`https://api.openrouteservice.org/v2/directions/driving-car/geojson`,
					{ coordinates: newLines },
					{
						headers: {
							"Content-Type": "application/json; charset=utf-8",
							Authorization: "5b3ce3597851110001cf62481ecca82985b54149bccc2f976e7d8699",
						},
					}
				)
				.then((resp) => {
					map.current.on("load", function () {
						map.current.addSource("lines", {
							type: "geojson",
							data: resp.data.features[0],
						});
						//Add pulsating dot and the end of the route
						const g = [];
						mapData.forEach((el) => {
							if (el.properties.bizActivity === "In Transit") {
								g.push(el);
							}
						});
						map.current.addSource("f-point", {
							type: "geojson",
							data: {
								type: "FeatureCollection",
								features: g,
							},
						});

						map.current.addLayer({
							id: "f-point",
							source: "f-point",
							type: "circle",
							paint: {
								"circle-radius": 5,
								"circle-color": "#007cbf",
							},
						});
						if (mapData[mapData.length - 1].properties.bizActivity === "In Transit") {
							map.current.addImage("pulsing-dot", pulsingDot, { pixelRatio: 2 });

							map.current.addSource("dot-point", {
								type: "geojson",
								data: {
									type: "FeatureCollection",
									features: [
										{
											type: "Feature",
											geometry: {
												type: "Point",
												coordinates: resp.data.features[0].geometry.coordinates[resp.data.features[0].geometry.coordinates.length - 1], // icon position [lng, lat]
											},
										},
									],
								},
							});
							//Add lines on the map Layer

							map.current.addLayer({
								id: "layer-with-pulsing-dot",
								type: "symbol",
								source: "dot-point",
								layout: {
									"icon-image": "pulsing-dot",
								},
							});
						}
						map.current.addLayer({
							id: "lines-viz",
							type: "line",
							source: "lines",
							layout: {
								"line-join": "round",
								"line-cap": "round",
							},
							paint: {
								"line-color": "#93b5b3",
								"line-width": 3,
							},
						});
					});
				})
				.catch((e) => {
					setMapError(e.message);
					console.log(e);
				});
		}

		//Set markers on the Map
		mapData.forEach((marker) => {
			if (marker.properties.bizActivity !== "In Transit") {
				var markerNew = new mapboxgl.Marker({
					color: "#0d6d8a",
				})
					.setLngLat([marker.geometry.coordinates[0], marker.geometry.coordinates[1]])
					.addTo(map.current);
				setNewMarkers((markers) =>
					markers.concat({
						id: marker.properties.bizActivityID,
						name: marker.properties.bizAgent,
						marker: markerNew,
						clicked: false,
						type: "marker",
					})
				);
			}
		});
		// return () => {
		// 	map.current.remove();
		// };
		// eslint-disable-next-line
	}, [clicked, mapData]);
	if (mapError) {
		return <div>{mapError}</div>;
	} else return <div ref={mapContainer} />;
}
