import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import AppHeader from "./Components/UI/appHeader";
import AppFooter from "./Components/UI/appFooter";
import TracePage from "./Pages/TracePage";
import LocationPage from "./Pages/LocationPage";
import LandingPage from "./Pages/LandingPage";
import AgentPage from "./Pages/AgentPage";
import DocumentPage from "./Pages/DocumentPage";
import ProductPage from "./Pages/ProductPage";

import { TimeLineContext } from "./Store/TimeLineContext";

import "./Assets/styles/App.css";

function App() {
	const [addressData, setAddressData] = useState(null);
	const [productData, setProductData] = useState(null);
	const [agent, setAgent] = useState(null);
	return (
		<div className="App">
			<>
				<AppHeader />
				<Router>
					<Switch>
						<Route exact path="/" component={LandingPage} />
						<TimeLineContext.Provider value={{ addressData, setAddressData, productData, setProductData, agent, setAgent }}>
							<Route path="/trace" component={TracePage} />
							<Route path="/info/agent" component={AgentPage} />
							<Route path="/info/location" component={LocationPage} />
							<Route path="/info/document" component={DocumentPage} />
							<Route path="/info/product" component={ProductPage} />
						</TimeLineContext.Provider>
					</Switch>
				</Router>
				<AppFooter />
			</>
		</div>
	);
}
export default App;
