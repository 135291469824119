import goodsReducer from "./goodsReducer";
import userReducer from "./userReducer";
//import addressReducer from "./addressReducer";

//import documentReducer from "./documentReducer";

import { combineReducers } from "redux";

const rootReducer = combineReducers({
	//addressReducer,
	userReducer,
	goodsReducer,
	//documentReducer,
});

export default rootReducer;
