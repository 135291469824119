import { useLocation } from "react-router";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import CofE from "../Assets/images/CofE-Example.jpg";

export default function DocumentPage() {
	const { push } = useHistory();

	return (
		<div className="App-body">

				<div className="content">
					<div className="productData">
						<div className="back-link" onClick={() => push("/trace")}>
							<span className="material-icons">fast_rewind</span>
						</div>

						<p className="productDataTitle">Document Name</p>
						<p className="data-small">Document ID</p>

						<div className="dataSegment">
							<img src={CofE} alt="" className="document-image" />
						</div>
						<p className="blue-text">Filetype: PDF</p>
					</div>
				</div>

		</div>
	);
}
