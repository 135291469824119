import React, { Fragment, useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router";
import moment from "moment";

import "../Assets/styles/partials/result.css";

import { fetchProduct, fetchProductTimeLine } from "../Store/Actions/goodsActions";
import { queryParams } from "../Store/GetSearch";

import { TimeLineContext } from "../Store/TimeLineContext";
import Map from "../Components/Visualisations/Map";
import Logo from "../Assets/images/logos/silubi-symbol.svg";
import scanGraphic from "../Assets/images/scanGraphic.svg";
import exploreArrows from "../Assets/images/exploreArrows.svg";
import iconCup from "../Assets/images/exploreCup.svg";
import iconPin from "../Assets/images/explorePin.svg";
import iconDescription from "../Assets/images/icons/icon-description.svg";
import iconFlavour from "../Assets/images/icons/icon-flavour.svg";

export default function TracePage() {
	const dispatch = useDispatch();
	const { push } = useHistory();
	const { search } = useLocation();

	const { timeLineData, timeLineError, productData, productError } = useSelector((state) => state.goodsReducer);
	const { setAddressData, setProductData, setAgent } = useContext(TimeLineContext);

	const [clickedStep, setClickedStep] = useState(null);
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	const prodID = queryParams(search, "gSerial");

	//Fetch the timeline of the product
	useEffect(() => {
		if (prodID && !timeLineData) {
			dispatch(fetchProduct(prodID));
			dispatch(fetchProductTimeLine(prodID));
		} else {
			setError({ message: "Scan a Silubi™ QR code to start" });
			setIsLoading(false);
		}

		// eslint-disable-next-line
	}, [prodID, timeLineData]);

	useEffect(() => {
		if (timeLineError || productError) {
			setError(timeLineError || productError);
			setIsLoading(false);
		}
	}, [timeLineError, productError]);
	useEffect(() => {
		if (timeLineData && productData) {
			setError(null);
			setIsLoading(false);
		}
	}, [timeLineData, productData]);

	function handlePurchaseProduct() {
		window.location.href = "https://rawcoffeecompany.com/collections/single-origin/products/colombia-planadas";
	}
	function handleShowAgent(el) {
		setAgent({ aSerial: el.bizMarker.properties.bizAgentID.slice(56), locationData: el });

		push("/info/agent");
	}
	function handleShowLocation(value) {
		setAddressData(value);
		push("/info/location");
	}
	function handleShowProduct(value) {
		productData[0].bizAgent = value.bizAgent;
		productData[0].status = value.bizActivity;
		productData[0].bizAddress = `${value.bizAddress}, ${value.bizLocationCity}, ${value.bizLocationCountry}`;
		setProductData(productData[0]);
		push("/info/product");
	}
	function handleShowDocument() {
		push("/info/document");
	}
	function handleClickedStep(value) {
		if (clickedStep && clickedStep.bizActivityID === value.bizActivityID) setClickedStep({ bizActivityID: value.bizActivityID });
		else setClickedStep(value);
	}

	return (
		<div className="App-body">
			{isLoading ? (
				<div className="content">
					<div className="productData">
						<div className="loader">
							<img src={Logo} alt="Sil" />
						</div>
						<div>Retrieving Data...</div>
					</div>
				</div>
			) : error ? (
				<div className="content">
					<div className="productData">
						<div className="messaging">
							<img src={scanGraphic} alt="" />
						</div>
						<div className="messaging">{error.message}</div>
					</div>
				</div>
			) : (
				<div
					className="content"
					onClick={(e) => {
						if (e.target.className === "result-vis") setClickedStep(null); // Set the clicked marker to its normal state
					}}
				>
					{/* ------------------------------ Display the top product image section ------------------------------ */}
					<div
						className="productSplash"
						style={{ backgroundImage: `url(${"https://cdn.shopify.com/s/files/1/2399/1771/products/Planadas_Varients2_800x.png"})` }} // <-------------------- Data to come from Shopify API
					>
						<div className="productTitle">
							<div className="button-area">
								<div className="button" onClick={handlePurchaseProduct}>
									<div className="main-button">
										<span className="main-button-title">
											<span className="material-icons">shopping_bag</span>
											Buy
										</span>
									</div>
								</div>
							</div>
							{productData[0].title}
						</div>
						<div className="productExploreTab">Explore</div>
					</div>

					{/* ------------------------------ Display Product Data ------------------------------ */}
					<div className="productData">
						<div className="productQuickStats">
							<div className="productQuickStats-score">
								<img src={iconCup} alt="" />
								<p className="data-small">88.85</p>
							</div>
							<div className="productQuickStats-arrows">
								<a href="#exploreSkip">
									<img src={exploreArrows} alt="" />
								</a>
							</div>
							<div className="productQuickStats-origin">
								<img src={iconPin} alt="" />
								<p className="data-small">{productData[0].country}</p>
							</div>
							<a id="exploreSkip"></a>
						</div>

						<div className="dataSegment">
							<div className="dataSegmentdata">
								<div className="dataSegmentdata-left">
									<img src={iconFlavour} alt="Mouth Icon" />
								</div>
								<div className="dataSegmentdata-right">Fruits, Nectarine &amp; Smooth</div> {/* <-------------------- Data to come from Cropster API */}
							</div>
						</div>

						<div className="dataSegment">
							<div className="dataSegmentdata">
								<div className="dataSegmentdata-left">
									<img src={iconDescription} alt="Description Icon" />
								</div>
								<div className="dataSegmentdata-right">{productData[0].description}</div> {/* <-------------------- Data to come from Shopify API */}
							</div>
						</div>

						<div className="dataSegment">
							<p className="data-small">Name: {productData[0].title}</p>
							<p className="data-small">
								Size: {productData[0].weight.low}
								{productData[0].unit} {productData[0].package}
								<span className="grey-text"> | </span>
								Serial #: {productData[0].id.slice(74)}
								<span className="grey-text"> | </span>
								Type: {productData[0].type}
							</p>
						</div>

						{/* ------------------------------ Display Product Documents ------------------------------ */}
						<div className="pageSegment">Product Certificates</div>
						<div className="docSegment" onClick={handleShowDocument}>
							<div className="docSegmentdata">
								<div className="docSegmentdata-left">
									<img src="https://worldreservetrust.com/wp-content/themes/worldreservetrust/images/icons/download_icons/iconPDF.png" alt="PDF Icon" />{" "}
								</div>
								<div className="docSegmentdata-right">Certificate_of_Origin.pdf</div>
							</div>
						</div>
						<div className="docSegment" onClick={handleShowDocument}>
							<div className="docSegmentdata">
								<div className="docSegmentdata-left">
									<img src="https://worldreservetrust.com/wp-content/themes/worldreservetrust/images/icons/download_icons/iconPDF.png" alt="PDF Icon" />{" "}
								</div>
								<div className="docSegmentdata-right">anotherfile.pdf</div>
							</div>
						</div>
						<div className="docSegment" onClick={handleShowDocument}>
							<div className="docSegmentdata">
								<div className="docSegmentdata-left">
									<img src="https://worldreservetrust.com/wp-content/themes/worldreservetrust/images/icons/download_icons/iconPDF.png" alt="PDF Icon" />{" "}
								</div>
								<div className="docSegmentdata-right">certificate_name.file</div>
							</div>
						</div>

						{/* ------------------------------ Display Product Journey Visualisations ------------------------------ */}
						<div className="pageSegment">Product Journey</div>

						<div className="map-vis">
							<Map
								clicked={clickedStep}
								mapData={timeLineData.mapData}
								setClickedStep={() => {
									setClickedStep(null);
								}}
							/>
						</div>

						<div className="dataSegment">
							<div className="timeline-vis">
								{timeLineData.data
									.filter((thing, index, self) => index === self.findIndex((t) => t.bizActivity === thing.bizActivity && thing.bizAgent))
									.map((el, index) => (
										<Fragment key={index}>
											<div
												className={index === 0 ? "timeline-start" : index === timeLineData.data.length - 1 ? "timeline-stop" : "timeline-node"}
												onClick={() => {
													if (el.bizActivity !== "In Transit") handleClickedStep(el);
												}}
											/>
											<div className="timeline-step">
												<p className="timeline-agent">
													<span
														className="data-link"
														onClick={() => {
															handleShowProduct(el);
														}}
													>
														{el.bizActivity}
													</span>
													&nbsp;
													<span className="data-small">by</span>
													&nbsp;
													<span
														className="data-link"
														onClick={() => {
															console.log(el);
															handleShowAgent(el);
														}}
													>
														{el.bizAgent}
													</span>
												</p>
												<p
													className="timeline-data"
													onClick={() => {
														handleShowLocation(el);
													}}
												>
													{el.bizActivity === "In Transit" ? null : (
														<>
															<span className="timeline-link">{el.bizLocationCountry}</span>
															<span className="grey-text"> | </span>
														</>
													)}

													{el.bizActivity === "In Transit" ? null : (
														<>
															{el.bizLocationCity}
															<span className="grey-text"> | </span>
														</>
													)}

													{moment(el.bizTime).format("DD/MM/YYYY")}
												</p>
											</div>
										</Fragment>
									))}
							</div>
						</div>
					</div>
				</div>
			)}

			{/* {isPayModal && productData && (
				<PayModal
					// geolocation={geolocation}
					query={queryParams(search, "productID")}
					productData={productData[0]}
					closeFunction={() => {
						setIsPayModal(false);
					}}
				/>
			)} */}
		</div>
	);
}
