import axios from "axios";

export const fetchAgent = (value) => {
	return (dispatch) => {
		axios
			.get(`${process.env.REACT_APP_AWS_API_URL}/user/fetch_user?userID=${value}&action=fetch_user`, {
				headers: { "x-api-key": process.env.REACT_APP_API_KEY },
			})
			.then(({ data }) => {
				const result = data[0]._fields[0];

				dispatch({ type: "AGENT_DATA_RECEIVED", result });
			})
			.catch((error) => {
				dispatch({ type: "AGENT_DATA_ERROR", error });
			});
	};
};
