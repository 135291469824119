import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { TimeLineContext } from "../Store/TimeLineContext";

export default function ProductPage() {
	const { push } = useHistory();

	const { productData, setAgent } = useContext(TimeLineContext);
	function handleShowAgent() {
		setAgent(productData.aSerial.slice(56));
		push("/info/agent");
	}
	useEffect(() => {
		if (!productData) push("/trace");
		// eslint-disable-next-line
	}, [productData]);

	return (
		<div className="App-body">
			{productData && (
				<div className="content">
					<div className="productData">
						<div className="back-link" onClick={() => push("/trace")}>
							<span className="material-icons">fast_rewind</span>
						</div>

						<p className="productDataTitle">{productData.title}</p>

						{/* <p>{productData.bizAddress}</p> */}
						<p className="data-small">{productData.country}</p>

						<div
							className="productPhoto"
							style={{ backgroundImage: `url(${"https://cdn.shopify.com/s/files/1/2399/1771/files/40_700x.png"})` }} // <-------------------- Data to come from Shopify API
						></div>

						<div className="dataSegment">
							{productData.status}
							&nbsp;by&nbsp;
							<span className="data-link" onClick={handleShowAgent}>
								{productData.bizAgent}
							</span>
							&nbsp;on&nbsp;
							{new Date(productData.date).toUTCString().slice(0, 17).trim()}
						</div>

						<div className="dataSegment">{productData.description}</div>

						{/* <p>
							{productData.weight.low}{productData.unit} {productData.type}
						</p> */}
					</div>
				</div>
			)}
		</div>
	);
}
