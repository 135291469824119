const goodsReducer = (state = { timeLineData: null, timeLineError: null, productData: null, productDataError: null }, action) => {
	switch (action.type) {
		case "PRODUCT_DATA_RECEIVED":
			return {
				...state,
				productData: action.result,
				productError: null,
			};

		case "PRODUCT_DATA_ERROR":
			return {
				...state,
				productError: action.error,
			};
		case "PRODUCT_TIMELINE_DATA_RECEIVED":
			return {
				...state,
				timeLineData: action.result,
				timeLineError: null,
			};

		case "PRODUCT_TIMELINE_DATA_ERROR":
			return {
				...state,
				timeLineError: action.error,
			};

		default:
			return state;
	}
};

export default goodsReducer;
