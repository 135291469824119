export function queryParams(search, key) {
	const searchParams = new URLSearchParams(search);
	const data = [];
	for (const param of searchParams) {
		data.push({ key: param[0], value: param[1] });
	}

	let val;
	data.forEach((el) => {
		if (el.key === key) {
			val = el.value;
		}
	});

	return val;
}
