import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { TimeLineContext } from "../Store/TimeLineContext";
import Map from "../Components/Visualisations/Map";

export default function LocationPage() {
	const { push } = useHistory();

	const { addressData } = useContext(TimeLineContext);

	useEffect(() => {
		if (!addressData) push("/trace");
		// eslint-disable-next-line
	}, [addressData]);

	return (
		<div className="App-body">

			{addressData && (
				<div className="content">
					<div className="productData">
						<div className="back-link" onClick={() => push("/trace")}>
							<span className="material-icons">fast_rewind</span>
						</div>

						<p className="productDataTitle">Asociación de Productores Egológicos de Planadas (ASOPEP)</p>

						<p className="data-small">
							{/* {addressData.bizAddress} */}
							{addressData.bizLocationCity}, {addressData.bizLocationCountry}
						</p>

						<div className="productPhoto"
							style={{ backgroundImage: `url(${"https://cdn.shopify.com/s/files/1/2399/1771/files/35_700x.png"})` }} // <-------------------- Data to come from Shopify API
						>
						</div>

						<div className="map-vis">
							<Map mapData={[addressData.bizMarker]} />
						</div>

						<div className="dataSegment">A flourishing organization of 168 coffee farming families makes up the Asociación de Productores Egológicos de Planadas (ASOPEP), one of the few producers of certified Fair Trade and Organic coffee in Colombia. It’s no small wonder that one can find fantastic quality coffee being produced in and around Planadas. The Idyllic town is blessed with warm days, cool nights, excellent elevation (1300-2170 masl), and healthy trees.</div>
					</div>
				</div>
			)}

		</div>
	);
}
