import axios from "axios";

// export const fetchGoods = (aSerial) => {
// 	const cancelTokenSource = axios.CancelToken.source();
// 	return (dispatch) => {
// 		axios
// 			.get(`${process.env.REACT_APP_AWS_API_URL}/goods/fetch_goods?aSerial=${aSerial}&action=fetch_goods`, {
// 				timeout: 3 * 60 * 1000,
// 				cancelToken: cancelTokenSource.token,
// 			})
// 			.then(({ data, status }) => {
// 				if (status === 200) {
// 					const result = [];
// 					data.forEach((record) => {
// 						result.push(record._fields[0]);
// 					});

// 					dispatch({ type: "GOODS_DATA_RECEIVED", result });
// 				} else if (status === 400 || status === 504) throw new Error("Network Error");
// 				else throw new Error("You have no products in inventory");
// 			})
// 			.catch((error) => {
// 				dispatch({ type: "GOODS_ERROR", error });
// 			});
// 	};
// };

export const fetchProduct = (gSerial) => {
	return (dispatch) => {
		axios
			.get(`${process.env.REACT_APP_AWS_API_URL}/goods/fetch_good?gSerial=${gSerial}&action=fetch_good`, {
				headers: { "x-api-key": process.env.REACT_APP_API_KEY },
			})
			.then(({ data, status }) => {
				if (status === 504) throw new Error("It took too long to get data,please try again");
				if (status === 400) throw new Error("There was an error during fetching product data");
				if (status === 200) {
					if (data.length === 0) throw new Error(`No data found`);
					const result = data[0]._fields;

					console.log(result);
					if (!result[0].country || !result[0].city) {
						console.log("No city or country is in data");
						const coordinates = result[0].geolocation.split(",");

						axios
							.get(
								`https://api.mapbox.com/geocoding/v5/mapbox.places/${coordinates[0]},${coordinates[1]}.json?access_token=pk.eyJ1IjoicHIxZGUiLCJhIjoiY2twZnAwamZkMGszODJ3cmllYTR6MmNtdyJ9.gprPfGqXmOufIdWeV7fK7g`
							)
							.then(({ data }) => {
								result[0].country = data.features[5].text;
								result[0].city = data.features[2].text;
							})
							.catch((error) => {
								dispatch({ type: "PRODUCT_DATA_ERROR", error });
							});
					}

					return result;
				}
			})
			.then((result) => {
				console.log("Sending result to user");
				dispatch({ type: "PRODUCT_DATA_RECEIVED", result });
			})
			.catch((error) => {
				dispatch({ type: "PRODUCT_DATA_ERROR", error });
			});
	};
};

export const fetchProductTimeLine = (gSerial) => {
	return (dispatch) => {
		axios
			.get(`${process.env.REACT_APP_AWS_API_URL}/timeline/fetch_timeline?gSerial=${gSerial}&action=fetch_timeline`, {
				headers: { "x-api-key": process.env.REACT_APP_API_KEY },
			})
			.then(({ data }) => {
				if (data.length === 0) {
					throw new Error("No item data found");
				} else {
					const result = { mapData: [], data: [] };

					data.forEach((el) => {
						if (el._fields[0]) {
							result.data.push(el._fields[0]);
							result.mapData.push(el._fields[0].bizMarker);
						}
					});

					dispatch({ type: "PRODUCT_TIMELINE_DATA_RECEIVED", result });
				}
			})
			.catch((error) => {
				dispatch({ type: "PRODUCT_TIMELINE_DATA_ERROR", error });
			});
	};
};
